// import './App.css';
// import Login from './components/Login';
// import Signup from './components/Signup';
// import ClaimsForm from './components/ClaimsForm';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';
// import { useSelector } from 'react-redux';
// import Dashboard from './components/Dashboard';
// import UploadPdf from './components/UploadPdf';
// import DownloadPdfs from './components/DownloadPdfs';
// import UploadReport from './components/UploadReport';
// import Home from './components/Home';
// import UserForm from './components/UserForm';
// import UsersList from './components/UsersList';

// function App() {
//   const isLoggedIn = useSelector(state => state.auth?.isLoggedIn);
//   const { user,userType } = useSelector(state => state.auth);
//   const isuserAdmin = (user==="admin@claimguard.com")
//   const isAdminUser = (userType==="admin_user")
//   return (
//    <>
//     <ToastContainer />
//     <BrowserRouter>
//     <Routes>
//     <Route path='/' element={<Home/>} />
//     <Route path='/login' element={<Login/>} />
//     <Route path='/signup' element={<Signup/>} />
//     <Route path='/home' element={<Home/>} />

//     <Route>
//     <Route element={!isLoggedIn && <Login />}>
//     <Route path='/' element={<Dashboard/>} />
//     <Route path='/dashboard' element={<Dashboard/>} />
//     <Route path='/dashboard-success' element={<Dashboard/>} />
//     <Route path='/dashboard-failure' element={<Dashboard/>} />
//     <Route path='/dashboard-cancelled' element={<Dashboard/>} />


//     <Route path='/add-users' element={<UserForm/>} />
//     <Route path='/user-list' element={<UsersList/>} />

//     <Route path='/claimform' element={<ClaimsForm/>} />
//     <Route path='/downloadpdf' element={<DownloadPdfs/>} />

//     <Route path='/uploadreport' element={<UploadReport/>} />



//     <Route path='/uploadpdf' element={<UploadPdf/>} />
//     <Route path='/uploadpdf-success' element={<UploadPdf/>} />
//     <Route path='/uploadpdf-failure' element={<UploadPdf/>} />
//     <Route path='/uploadpdf-cancelled' element={<UploadPdf/>} />



//     </Route>
//     </Route>
//     </Routes>
//     </BrowserRouter>

//    </>


//   );
// }

// export default App;


import './App.css';
import Login from './components/Login';
import Signup from './components/Signup';
import ClaimsForm from './components/ClaimsForm';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import Dashboard from './components/Dashboard';
import UploadPdf from './components/UploadPdf';
import DownloadPdfs from './components/DownloadPdfs';
import UploadReport from './components/UploadReport';
import Home from './components/Home';
import UserForm from './components/UserForm';
import UsersList from './components/UsersList';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute
import AdminRoute from './components/AdminRoute';
import UserManagement from './components/UserManagement';
import UsageReport from './components/UsageReport';

function App() {
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn);
  const { user, userType } = useSelector(state => state.auth);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/home' element={<Home />} />

          {/* Protect certain routes */}
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/claimform' element={<ClaimsForm />} />
          <Route path='/downloadpdf' element={ <AdminRoute><DownloadPdfs /></AdminRoute>} />
          <Route path='/uploadreport' element={<AdminRoute><UploadReport /></AdminRoute>} />

          <Route path='/uploadpdf' element={<UploadPdf />} />
          <Route path='/uploadpdf-success' element={<UploadPdf />} />
          <Route path='/uploadpdf-failure' element={<UploadPdf />} />
          <Route path='/uploadpdf-cancelled' element={<UploadPdf />} />

          {/* Example of allowing only super_admin to access a route */}
          <Route path ='/user-management' element={<AdminRoute><UserManagement /></AdminRoute>} />
          <Route path ='/usage-report' element={<AdminRoute><UsageReport /></AdminRoute>} />
          <Route path='/add-users' element={<AdminRoute><UserForm /></AdminRoute>} />
          <Route path='/user-list' element={<AdminRoute><UsersList /></AdminRoute>} />

          {/* Public routes */}
          <Route path='/dashboard-success' element={<ProtectedRoute allowedRoles={['user']}><Dashboard /></ProtectedRoute>} />
          <Route path='/dashboard-failure' element={<ProtectedRoute allowedRoles={['user']}><Dashboard /></ProtectedRoute>} />
          <Route path='/dashboard-cancelled' element={<ProtectedRoute allowedRoles={['user']}><Dashboard /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
