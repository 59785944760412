import React, { useEffect, useState } from 'react';
import PaymentComponent from './PaymentComponent';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { toast } from 'react-toastify';
import Loader from './loader/loader';
import ModalComponent from './modal/ModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import { isPaidStatus } from '../components/redux/fileUploadSlice';
import axios from 'axios';
import { baseURL } from './utils/utils';

const Dashboard = () => {
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const date = new Date().toISOString().replace(/[-:.]/g, '');
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [folderExistence, setFolderExistence] = useState(false); // State to track folder existence [setFolderExistence]
  const { isPaid } = useSelector(state => state.fileUpload);
  const { user, userType } = useSelector(state => state?.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const isuserAdmin = (user === 'admin@claimguard.com');
  const isAdminUser = (userType === 'admin_user');

  // useEffect(()=>{
  //   checkReportsFolder();

  // },[location])


  useEffect(() => {
    // Check if the 'success' parameter exists
    // if (isuserAdmin || isAdminUser) {
    //   dispatch(isPaidStatus(true));
    //   handlePaymentSuccess(true)
    // }
    // if (isuserAdmin || isAdminUser) {
    //   if (!isPaid) {
    //     // console.log("🚀 ~ useEffect ~ isPaid:", isPaid)
    //     dispatch(isPaidStatus(true));
    //     handlePaymentSuccess(true);
    //   }
    // }
    // else {
    const paymentSuccess = location.pathname.includes('success');
    const paymentfailure = location.pathname.includes('failure' || 'cancelled');

    if (paymentSuccess) {
      // Update the state based on the payment success
      toast.success('Payment Successful. ');
      toast.info("Please Click Download Report To Download Report")
      dispatch(isPaidStatus(true));
      handlePaymentSuccess(true)
      navigate('/dashboard')

    }

    else if (paymentfailure && !isPaid) {
      toast.error('Payment Transaction Failed. Try Again');
      dispatch(isPaidStatus(false));
      handlePaymentSuccess(false)


    }


    // }
  }, [selectedFile, selectedFile2, dispatch]);

  useEffect(() => {
    checkReportsFolder();
  }, [location])

  useEffect(() => {
    setTimeout(() => {
      handleFileUpload();
    }, 200)
  }, [selectedFile])

  useEffect(() => {
    setTimeout(() => {
      handleMedicationFileUpload();
    }, 200)
  }, [selectedFile2])




  const checkReportsFolder = async () => {
    try {

      const response = await axios.get(`${baseURL}/check-folder/${user}`);
      setFolderExistence(response.data.exists);
      return response.data.exists;
    } catch (error) {
      // toast.error('Error checking reports: ' + (error.response?.data?.error || 'Unknown error'));
      return false;
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileChange2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    setLoading(true);
    setLoadingText("Uploading file...");
    formData.append('username', user);
    dispatch(isPaidStatus(false));

    try {
      const response = await fetch(`${baseURL}/upload`, {  // Update with your actual API endpoint
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {

        setLoading(false);
        toast.success('File uploaded successfully');
        if ((isuserAdmin || isAdminUser) && !isPaid) {
          dispatch(isPaidStatus(true));
          handlePaymentSuccess(true)
        }
        else {


          setIsModalOpen(true);
          setApiResponse(result);

        }

      } else {
        toast.error(result?.error[0] ?? 'File upload failed');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleMedicationFileUpload = async () => {
    if (!selectedFile2) return;

    const formData = new FormData();
    formData.append('file', selectedFile2);
    setLoading(true);
    setLoadingText("Uploading file...");

    try {
      const response = await fetch(`${baseURL}/upload-medication`, {  // Update with your actual API endpoint
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        setLoading(false);
        toast.success(result?.message ?? 'File uploaded successfully');
      } else {
        toast.error(result?.error ?? 'File upload failed');
      }
    } catch (error) {
      setLoading(false);

      toast.error(error);
    }
  };


  const handleGenerateReport = async () => {
    setLoading(true);
    setLoadingText("Downloading Report...");
    try {
      const response = await fetch(`${baseURL}/download`, {
        method: 'GET',
      });

      if (!response.ok) {
        const result = await response.json();
        toast.error('Failed Please upload file first to download file');
        throw new Error(result.error || 'Failed to download file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      // link.download = `validation_report_${new Date().toISOString().replace(/[-:.]/g, '')}.xlsx`;
      link.download = `validation_report.xlsx`;

      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      // !isuserAdmin && !isAdminUser && dispatch(isPaidStatus(false));
      navigate('/dashboard');
    } catch (error) {
      setLoading(false);
      // !isuserAdmin && dispatch(isPaidStatus(false));
      navigate('/dashboard');
    }
    // !isuserAdmin && !isAdminUser && dispatch(isPaidStatus(false));
    navigate('/dashboard');
    setLoading(false);

  };

  const handleGetTemplate = async () => {
    setLoading(true);
    setLoadingText("Downloading Template...");
    try {
      const response = await fetch(`${baseURL}/download-template`, {
        method: 'GET',
      });

      if (!response.ok) {
        // Handle the case where the file was not found or another error occurred
        const result = await response.json();
        throw new Error(result.error || 'Failed to download file');
      }

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      let filename = `Template.xlsx`; // Default filename

      link.download = filename;

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up and remove the link
      link.remove();

      // Revoke the object URL to free up memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error('Can Not Download Template. Please try again');
      setLoading(false);
    }
    setLoading(false);
  };

  const handlePaymentSuccess = async (paymentStatus) => {
    try {
      const response = await axios.post(`${baseURL}/payment-success/${user}`, {
        // username: user,
        payment_successful: paymentStatus
      });

      // Handle success response
      toast.success(response.data.message);
    } catch (error) {
      // Handle error response
      if (error.response) {
        // toast.error("Payment Error no new report will be genrated.");
      } else {
        toast.error('An error occurred while processing your request.');
      }
    }
  };

  return (
    <>
      <Loader loading={loading} text={loadingText} />
      <Navbar />
      <div className='flex flex-col justify-center items-center h-screen space-y-4'>
        {!isuserAdmin && !isAdminUser && <ModalComponent
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          apiResponse={apiResponse}
        />}
        <div className='bg-gray-200 flex flex-col gap-2 p-3 rounded-lg select-none'>
          <p className='p-1 text-blue-800 font-bold select-none'>Please Choose An Action</p>
          <div className=' gap-2 flex rounded mb-4 '>
            <label className='relative inline-block cursor-pointer'>
              <input
                type="file"
                accept=".xls, .xlsx"
                className='absolute inset-0 w-full h-full opacity-0 cursor-pointer'
                onChange={handleFileChange}
              />
              <button
                className='bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-green-600 transition duration-200'
              >
                Upload An Excel File
              </button>
            </label>
            {/* <label className='relative inline-block cursor-pointer'>
              <input
                type="file"
                accept=".xls, .xlsx"
                className='absolute inset-0 w-full h-full opacity-0 cursor-pointer'
                onChange={handleFileChange2}
              />
              <button
                className='bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-green-600 transition duration-200'
              >
                Upload Medication File
              </button>
            </label> */}
            {(isPaid || folderExistence) && <button
              className='bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-green-600 transition duration-200'
              onClick={() => { handleGenerateReport() }}
            >
              Download Report
            </button>}

            <button className='bg-blue-800 text-white font-semibold py-2 px-4 rounded shadow hover:bg-blue-600 transition duration-200'
              onClick={() => { handleGetTemplate() }}>
              Download An Empty Template File (EXCEL)
            </button>

            <Link to="/claimform">
              <button className='bg-green-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-green-600 transition duration-200'>
                Add Data Manually
              </button>
            </Link>
          </div>
        </div>


        <div className='bg-gray-200 flex flex-row gap-3 p-3 rounded-lg '>

          <Link to="/uploadpdf" className='text-center'>
            <button className='bg-red-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-red-600 transition duration-200'>
              Upload Pdf Files For Claims
            </button>
          </Link>

        </div>
      </div>
    </>
  );
}

export default Dashboard;
