// // // // // src/slices/formDataSlice.js
// // // // import { createSlice } from '@reduxjs/toolkit';

// // // // const initialFormState = {
// // // //   'INV_NO': '', 'FILE_NO/MRN_NO': '', 'ID_NO/IQAMA_NO': '', 'MBR_NAME': '', 'MBR_NO': '',
// // // //   'GENDER': '', 'INV_DATE': '', 'INCUR_DATE_FROM': '', 'ICD10_1': '', 'ICD DESCRIPTION_1': '', 
// // // //   'ICD10_2': '', 'ICD DESCRIPTION_2': '', 'ICD10_3': '', 'ICD DESCRIPTION_3': '', 'ICD10_4': '', 
// // // //   'ICD DESCRIPTION_4': '', 'CHIEF_COMPLAIN': '', 'TEMPERATURE': '', 'BLOOD_PRESSURE': '', 
// // // //   'PULSE': '', 'DURATION_OF_ILLNESS': '', 'TOOTH_NO': '', 'PREAUTHRIZATION_NO/APPROVAL_NO': '', 
// // // //   'SERV_TYPE': '', 'SPECIALITY': '', 'NPHIES_CODE': '', 'SERV_CODE': '', 'SERV_DESC': '', 
// // // //   'QTY': '', 'PRESENT_AMT': ''
// // // // };

// // // // const initialState = {
// // // //   formData: [{ ...initialFormState }],
// // // //   dataDict: {
// // // //     'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
// // // //     'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
// // // //     'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
// // // //     'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
// // // //     'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
// // // //     'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
// // // //     'QTY': [], 'PRESENT_AMT': []
// // // //   }
// // // // };

// // // // const formDataSlice = createSlice({
// // // //     name: 'formData',
// // // //     initialState,
// // // //     reducers: {
// // // //       setFormData: (state, action) => {
// // // //         state.formData = action.payload;
// // // //       },
// // // //       setDataDict: (state, action) => {
// // // //         state.dataDict = action.payload;
// // // //       },
// // // //       addRow: (state) => {
// // // //         const lastRow = state.formData[state.formData.length - 1];
// // // //         const isEmptyRow = Object.values(lastRow).every(value => value === '');
  
// // // //         if (!isEmptyRow) {
// // // //           const newDataDict = { ...state.dataDict };
  
// // // //           Object.keys(lastRow).forEach(key => {
// // // //             if (lastRow[key] !== '') {
// // // //               newDataDict[key] = [...(newDataDict[key] || []), lastRow[key]];
// // // //             }
// // // //           });
  
// // // //           state.dataDict = newDataDict;
// // // //           state.formData = [...state.formData, { ...initialFormState }];
// // // //         } else {
// // // //           alert('Please fill out the row before adding a new one.');
// // // //         }
// // // //       },
// // // //       removeRow: (state, action) => {
// // // //         if (state.formData.length > 1) {
// // // //           const rowToRemove = state.formData[action.payload];
// // // //           state.formData = state.formData.filter((_, index) => index !== action.payload);
  
// // // //           const newDataDict = { ...state.dataDict };
  
// // // //           Object.keys(rowToRemove).forEach(key => {
// // // //             const valueIndex = newDataDict[key]?.indexOf(rowToRemove[key]);
// // // //             if (valueIndex > -1) {
// // // //               newDataDict[key].splice(valueIndex, 1);
// // // //             }
// // // //           });
  
// // // //           state.dataDict = newDataDict;
// // // //         } else {
// // // //           state.formData = [{ ...initialFormState }];
// // // //           state.dataDict = {
// // // //             'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
// // // //             'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
// // // //             'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
// // // //             'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
// // // //             'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
// // // //             'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
// // // //             'QTY': [], 'PRESENT_AMT': []
// // // //           };

          
// // // //         }
// // // //       }
// // // //     }
// // // //   });
  
// // // //   export const { setFormData, setDataDict, addRow, removeRow } = formDataSlice.actions;
// // // //   export default formDataSlice.reducer;
// // // // src/slices/formDataSlice.js
// // // import { createSlice } from '@reduxjs/toolkit';

// // // const initialFormState = {
// // //   'INV_NO': '', 'FILE_NO/MRN_NO': '', 'ID_NO/IQAMA_NO': '', 'MBR_NAME': '', 'MBR_NO': '',
// // //   'GENDER': '', 'INV_DATE': '', 'INCUR_DATE_FROM': '', 'ICD10_1': '', 'ICD DESCRIPTION_1': '', 
// // //   'ICD10_2': '', 'ICD DESCRIPTION_2': '', 'ICD10_3': '', 'ICD DESCRIPTION_3': '', 'ICD10_4': '', 
// // //   'ICD DESCRIPTION_4': '', 'CHIEF_COMPLAIN': '', 'TEMPERATURE': '', 'BLOOD_PRESSURE': '', 
// // //   'PULSE': '', 'DURATION_OF_ILLNESS': '', 'TOOTH_NO': '', 'PREAUTHRIZATION_NO/APPROVAL_NO': '', 
// // //   'SERV_TYPE': '', 'SPECIALITY': '', 'NPHIES_CODE': '', 'SERV_CODE': '', 'SERV_DESC': '', 
// // //   'QTY': '', 'PRESENT_AMT': ''
// // // };

// // // const initialState = {
// // //   formData: [{ ...initialFormState }],
// // //   dataDict: {
// // //     'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
// // //     'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
// // //     'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
// // //     'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
// // //     'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
// // //     'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
// // //     'QTY': [], 'PRESENT_AMT': []
// // //   }
// // // };

// // // const formDataSlice = createSlice({
// // //   name: 'formData',
// // //   initialState,
// // //   reducers: {
// // //     setFormData: (state, action) => {
// // //       state.formData = action.payload;
// // //     },
// // //     setDataDict: (state, action) => {
// // //       state.dataDict = action.payload;
// // //     },
// // //     addRow: (state) => {
// // //       const lastRow = state.formData[state.formData.length - 1];
// // //       const isEmptyRow = Object.values(lastRow).every(value => value === '');

// // //       if (!isEmptyRow) {
// // //         const newDataDict = { ...state.dataDict };

// // //         Object.keys(lastRow).forEach(key => {
// // //           if (lastRow[key] !== '') {
// // //             newDataDict[key] = [...(newDataDict[key] || []), lastRow[key]];
// // //           }
// // //         });

// // //         state.dataDict = newDataDict;
// // //         state.formData = [...state.formData, { ...initialFormState }];
// // //       } else {
// // //         alert('Please fill out the row before adding a new one.');
// // //       }
// // //     },
// // //     removeRow: (state, action) => {
// // //       if (state.formData.length > 1) {
// // //         const rowToRemove = state.formData[action.payload];
// // //         state.formData = state.formData.filter((_, index) => index !== action.payload);

// // //         const newDataDict = { ...state.dataDict };

// // //         Object.keys(rowToRemove).forEach(key => {
// // //           const valueIndex = newDataDict[key]?.indexOf(rowToRemove[key]);
// // //           if (valueIndex > -1) {
// // //             newDataDict[key].splice(valueIndex, 1);
// // //           }
// // //         });

// // //         state.dataDict = newDataDict;
// // //       } else {
// // //         state.formData = [{ ...initialFormState }];
// // //         state.dataDict = {
// // //           'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
// // //           'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
// // //           'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
// // //           'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
// // //           'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
// // //           'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
// // //           'QTY': [], 'PRESENT_AMT': []
// // //         };
// // //       }
// // //     },
// // //     resetForm: (state) => {
// // //       state.formData = [{ ...initialFormState }];
// // //       state.dataDict = {
// // //         'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
// // //         'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
// // //         'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
// // //         'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
// // //         'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
// // //         'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
// // //         'QTY': [], 'PRESENT_AMT': []
// // //       };
// // //     }
// // //   }
// // // });

// // // export const { setFormData, setDataDict, addRow, removeRow, resetForm } = formDataSlice.actions;
// // // export default formDataSlice.reducer;

// // import { createSlice } from '@reduxjs/toolkit';
// // import { toast } from 'react-toastify';

// // const initialFormState = {
// //   'INV_NO': '', 'FILE_NO/MRN_NO': '', 'ID_NO/IQAMA_NO': '', 'MBR_NAME': '', 'MBR_NO': '',
// //   'GENDER': '', 'INV_DATE': '', 'INCUR_DATE_FROM': '', 'ICD10_1': '', 'ICD DESCRIPTION_1': '', 
// //   'ICD10_2': '', 'ICD DESCRIPTION_2': '', 'ICD10_3': '', 'ICD DESCRIPTION_3': '', 'ICD10_4': '', 
// //   'ICD DESCRIPTION_4': '', 'CHIEF_COMPLAIN': '', 'TEMPERATURE': '', 'BLOOD_PRESSURE': '', 
// //   'PULSE': '', 'DURATION_OF_ILLNESS': '', 'TOOTH_NO': '', 'PREAUTHRIZATION_NO/APPROVAL_NO': '', 
// //   'SERV_TYPE': '', 'SPECIALITY': '', 'NPHIES_CODE': '', 'SERV_CODE': '', 'SERV_DESC': '', 
// //   'QTY': '', 'PRESENT_AMT': ''
// // };

// // const mandatoryFields = [
// //   'INV_NO', 'FILE_NO/MRN_NO', 'ID_NO/IQAMA_NO', 'MBR_NAME', 'MBR_NO',
// //   'INV_DATE', 'INCUR_DATE_FROM', 'ICD10_1', 'ICD DESCRIPTION_1', 'TEMPERATURE', 
// //   'BLOOD_PRESSURE', 'PULSE', 'DURATION_OF_ILLNESS', 'SERV_TYPE', 'SPECIALITY',
// //   'NPHIES_CODE', 'SERV_CODE', 'SERV_DESC', 'QTY', 'PRESENT_AMT'
// // ];

// // const initialState = {
// //   formData: [{ ...initialFormState }],
// //   dataDict: {
// //     'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
// //     'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
// //     'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
// //     'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
// //     'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
// //     'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
// //     'QTY': [], 'PRESENT_AMT': []
// //   }
// // };

// // const formDataSlice = createSlice({
// //   name: 'formData',
// //   initialState,
// //   reducers: {
// //     setFormData: (state, action) => {
// //       state.formData = action.payload;
// //     },
// //     setDataDict: (state, action) => {
// //       state.dataDict = action.payload;
// //     },
// //     addRow: (state) => {
// //       const lastRow = state.formData[state.formData.length - 1];

// //       // Check if all mandatory fields are filled
// //       const isMandatoryFilled = mandatoryFields.every(field => lastRow[field] !== undefined && lastRow[field] !== '');

// //       if (isMandatoryFilled) {
// //         const newDataDict = { ...state.dataDict };

// //         // Add filled values to dataDict
// //         Object.keys(lastRow).forEach(key => {
// //           if (lastRow[key] !== '') {
// //             newDataDict[key] = [...(newDataDict[key] || []), lastRow[key]];
// //           }
// //         });

// //         state.dataDict = newDataDict;
// //         state.formData = [...state.formData, { ...initialFormState }];
// //         toast.success('Row added successfully!', { autoClose: 100 }, { position: 'top-center' }, { pauseOnHover: true }, { draggable: true }, { progress: undefined });
// //       } else {
// //         toast.error('Please fill out all mandatory fields before adding a new row.');
// //       }
// //     },
// //     removeRow: (state, action) => {
// //       if (state.formData.length > 1) {
// //         const rowToRemove = state.formData[action.payload];
// //         state.formData = state.formData.filter((_, index) => index !== action.payload);

// //         const newDataDict = { ...state.dataDict };

// //         Object.keys(rowToRemove).forEach(key => {
// //           const valueIndex = newDataDict[key]?.indexOf(rowToRemove[key]);
// //           if (valueIndex > -1) {
// //             newDataDict[key].splice(valueIndex, 1);
// //           }
// //         });

// //         state.dataDict = newDataDict;
// //       } else {
// //         state.formData = [{ ...initialFormState }];
// //         state.dataDict = {
// //           'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
// //           'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
// //           'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
// //           'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
// //           'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
// //           'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
// //           'QTY': [], 'PRESENT_AMT': []
// //         };
// //       }
// //     },
// //     resetForm: (state) => {
// //       state.formData = [{ ...initialFormState }];
// //       state.dataDict = {
// //         'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
// //         'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
// //         'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
// //         'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
// //         'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
// //         'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
// //         'QTY': [], 'PRESENT_AMT': []
// //       };
// //     }
// //   }
// // });

// // export const { setFormData, setDataDict, addRow, removeRow, resetForm } = formDataSlice.actions;
// // export default formDataSlice.reducer;

// import { createSlice } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';

// const initialFormState = {
//   'INV_NO': '', 'FILE_NO/MRN_NO': '', 'ID_NO/IQAMA_NO': '', 'MBR_NAME': '', 'MBR_NO': '',
//   'GENDER': '', 'INV_DATE': '', 'INCUR_DATE_FROM': '', 'ICD10_1': '', 'ICD DESCRIPTION_1': '', 
//   'ICD10_2': '', 'ICD DESCRIPTION_2': '', 'ICD10_3': '', 'ICD DESCRIPTION_3': '', 'ICD10_4': '', 
//   'ICD DESCRIPTION_4': '', 'CHIEF_COMPLAIN': '', 'TEMPERATURE': '', 'BLOOD_PRESSURE': '', 
//   'PULSE': '', 'DURATION_OF_ILLNESS': '', 'TOOTH_NO': '', 'PREAUTHRIZATION_NO/APPROVAL_NO': '', 
//   'SERV_TYPE': '', 'SPECIALITY': '', 'NPHIES_CODE': '', 'SERV_CODE': '', 'SERV_DESC': '', 
//   'QTY': '', 'PRESENT_AMT': ''
// };

// const mandatoryFields = [
//   'INV_NO', 'FILE_NO/MRN_NO', 'ID_NO/IQAMA_NO', 'MBR_NAME', 'MBR_NO',
//   'INV_DATE', 'INCUR_DATE_FROM', 'ICD10_1', 'ICD DESCRIPTION_1', 'TEMPERATURE', 
//   'BLOOD_PRESSURE', 'PULSE', 'DURATION_OF_ILLNESS', 'SERV_TYPE', 'SPECIALITY',
//   'NPHIES_CODE', 'SERV_CODE', 'SERV_DESC', 'QTY', 'PRESENT_AMT'
// ];

// const initialState = {
//   formData: [{ ...initialFormState }],
//   dataDict: {
//     'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
//     'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
//     'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
//     'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
//     'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
//     'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
//     'QTY': [], 'PRESENT_AMT': []
//   }
// };

// const formDataSlice = createSlice({
//   name: 'formData',
//   initialState,
//   reducers: {
//     setFormData: (state, action) => {
//       state.formData = action.payload;
//     },
//     setDataDict: (state, action) => {
//       state.dataDict = action.payload;
//     },
//     addRow: (state) => {
//       const lastRow = state.formData[state.formData.length - 1];

//       // Check if all mandatory fields are filled
//       const isMandatoryFilled = mandatoryFields.every(field => lastRow[field] !== undefined && lastRow[field] !== '');

//       if (isMandatoryFilled) {
//         const newDataDict = { ...state.dataDict };

//         // Add filled values to dataDict
//         Object.keys(lastRow).forEach(key => {
//           if (lastRow[key] !== '') {
//             newDataDict[key] = [...(newDataDict[key] || []), lastRow[key]];
//           }
//         });

//         state.dataDict = newDataDict;
//         // Reset the last row to avoid duplication
//         state.formData[state.formData.length - 1] = { ...initialFormState };
//         // Add a new row
//         state.formData.push({ ...initialFormState });
//       } else {
//         toast('Please fill out all mandatory fields before adding a new row.');
//       }
//     },
//     removeRow: (state, action) => {
//       if (state.formData.length > 1) {
//         const rowToRemove = state.formData[action.payload];
//         state.formData = state.formData.filter((_, index) => index !== action.payload);

//         const newDataDict = { ...state.dataDict };

//         Object.keys(rowToRemove).forEach(key => {
//           const valueIndex = newDataDict[key]?.indexOf(rowToRemove[key]);
//           if (valueIndex > -1) {
//             newDataDict[key].splice(valueIndex, 1);
//           }
//         });

//         state.dataDict = newDataDict;
//       } else {
//         state.formData = [{ ...initialFormState }];
//         state.dataDict = {
//           'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
//           'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
//           'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
//           'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
//           'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
//           'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
//           'QTY': [], 'PRESENT_AMT': []
//         };
//       }
//     },
//     resetForm: (state) => {
//       state.formData = [{ ...initialFormState }];
//       state.dataDict = {
//         'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
//         'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
//         'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
//         'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
//         'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
//         'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
//         'QTY': [], 'PRESENT_AMT': []
//       };
//     }
//   }
// });

// export const { setFormData, setDataDict, addRow, removeRow, resetForm } = formDataSlice.actions;
// export default formDataSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialFormState = {
  'INV_NO': '', 'FILE_NO/MRN_NO': '', 'ID_NO/IQAMA_NO': '', 'MBR_NAME': '', 'MBR_NO': '',
  'GENDER': '', 'INV_DATE': '', 'INCUR_DATE_FROM': '', 'ICD10_1': '', 'ICD DESCRIPTION_1': '', 
  'ICD10_2': '', 'ICD DESCRIPTION_2': '', 'ICD10_3': '', 'ICD DESCRIPTION_3': '', 'ICD10_4': '', 
  'ICD DESCRIPTION_4': '', 'CHIEF_COMPLAIN': '', 'TEMPERATURE': '', 'BLOOD_PRESSURE': '', 
  'PULSE': '', 'DURATION_OF_ILLNESS': '', 'TOOTH_NO': '', 'PREAUTHRIZATION_NO/APPROVAL_NO': '', 
  'SERV_TYPE': '', 'SPECIALITY': '', 'NPHIES_CODE': '', 'SERV_CODE': '', 'SERV_DESC': '', 
  'QTY': '', 'PRESENT_AMT': ''
};

const mandatoryFields = [
  'INV_NO',  'ICD10_1', 'ICD DESCRIPTION_1', 
  'NPHIES_CODE',  'SERV_DESC', 
];

const initialState = {
  formData: [{ ...initialFormState }],
  dataDict: {
    'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
    'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
    'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
    'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
    'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
    'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
    'QTY': [], 'PRESENT_AMT': []
  }
};

const formDataSlice = createSlice({
  name: 'formData',
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDataDict: (state, action) => {
      state.dataDict = action.payload;
    },
    addRow: (state) => {
      const lastRow = state.formData[state.formData.length - 1];

      // Check if all mandatory fields are filled
      const isMandatoryFilled = mandatoryFields.every(field => lastRow[field] !== undefined && lastRow[field] !== '');

      if (isMandatoryFilled) {
        const newDataDict = { ...state.dataDict };

        // Add filled values to dataDict
        Object.keys(lastRow).forEach(key => {
          // if (lastRow[key] !== '') {
            newDataDict[key] = [...(newDataDict[key]|| "" ), lastRow[key]];
          // }
        });

        state.dataDict = newDataDict;
        // Add a new row without modifying the existing one
        state.formData.push({ ...initialFormState });
      } else {
        toast.error('Please fill out all mandatory fields before adding a new row.',{autoClose: 1000});
      }
    },
    removeRow: (state, action) => {
      if (state.formData.length > 1) {
        const rowToRemove = state.formData[action.payload];
        state.formData = state.formData.filter((_, index) => index !== action.payload);

        const newDataDict = { ...state.dataDict };

        Object.keys(rowToRemove).forEach(key => {
          const valueIndex = newDataDict[key]?.indexOf(rowToRemove[key]);
          if (valueIndex > -1) {
            newDataDict[key].splice(valueIndex, 1);
          }
        });

        state.dataDict = newDataDict;
      } else {
        state.formData = [{ ...initialFormState }];
        state.dataDict = {
          'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
          'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
          'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
          'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
          'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
          'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
          'QTY': [], 'PRESENT_AMT': []
        };
      }
    },
    resetForm: (state) => {
      state.formData = [{ ...initialFormState }];
      state.dataDict = {
        'INV_NO': [], 'FILE_NO/MRN_NO': [], 'ID_NO/IQAMA_NO': [], 'MBR_NAME': [], 'MBR_NO': [],
        'GENDER': [], 'INV_DATE': [], 'INCUR_DATE_FROM': [], 'ICD10_1': [], 'ICD DESCRIPTION_1': [],
        'ICD10_2': [], 'ICD DESCRIPTION_2': [], 'ICD10_3': [], 'ICD DESCRIPTION_3': [], 'ICD10_4': [],
        'ICD DESCRIPTION_4': [], 'CHIEF_COMPLAIN': [], 'TEMPERATURE': [], 'BLOOD_PRESSURE': [],
        'PULSE': [], 'DURATION_OF_ILLNESS': [], 'TOOTH_NO': [], 'PREAUTHRIZATION_NO/APPROVAL_NO': [],
        'SERV_TYPE': [], 'SPECIALITY': [], 'NPHIES_CODE': [], 'SERV_CODE': [], 'SERV_DESC': [],
        'QTY': [], 'PRESENT_AMT': []
      };
    }
  }
});

export const { setFormData, setDataDict, addRow, removeRow, resetForm } = formDataSlice.actions;
export default formDataSlice.reducer;
